import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner_card = _resolveComponent("banner-card")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.promotionData, (data) => {
            return (_openBlock(), _createElementBlock("div", {
              key: data.id,
              class: "col col-md-4 col-xs-12"
            }, [
              _createVNode(_component_banner_card, {
                model: data,
                onSetDeleteId: $setup.setDeleteBannerId
              }, null, 8, ["model", "onSetDeleteId"])
            ]))
          }), 128))
        ]),
        _createVNode(_component_ConfirmModal, {
          id: "confirmDeleteBanner",
          ref: "modalRef",
          message: "Are you sure you want to delete this item? This action is\n            irreversible."
        }, {
          default: _withCtx(() => [
            _createElementVNode("button", {
              disabled: _ctx.loading,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.deleteBanner && $setup.deleteBanner(...args))),
              type: "button",
              class: "btn btn-primary px-10"
            }, " Delete ", 8, _hoisted_3)
          ]),
          _: 1
        }, 512)
      ]))
    : _createCommentVNode("", true)
}