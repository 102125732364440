
interface IPropsData {
  id: string;
  title: string;
  image: string;
  start: string;
  end: string;
  isActive: boolean;
  promotion: string;
  type: string;
  deleted: boolean;
  createdAt: string;
  updatedAt: string;
  description: string;
}

import { useStore } from 'vuex';
import { computed } from '@vue/runtime-core';
import { dateFormat } from '@/utils/helper/format';
import { Actions } from '@/store/modules/PromotionBannerModule';
import Swal from 'sweetalert2/dist/sweetalert2.js';

export default {
  props: {
    model: Object,
  },
  setup(props, { emit }) {
    const store = useStore();

    const data = computed({
      get: () => props.model as IPropsData,
      set: (value) => emit('update:model', value),
    });

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: '',
      customClass: {
        popup: 'colored-toast',
      },
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    return {
      dateFormat,
      data,
      async setDeleteId() {
        emit('set-delete-id', data.value.id);
      },
      async updateBannerStatus(e) {
        e.preventDefault();
        if (data.value.id) {
          try {
            await store.dispatch(Actions.UPDATE_PROMOTION_BANNER_STATUS, {
              id: data.value.id,
              isActive: data.value.isActive,
            });

            await Toast.fire({
              icon: 'success',
              title: `${data.value.title}: ${
                data.value.isActive ? 'Open' : 'Close'
              }`,
            });
          } catch (error) {
            console.error({ error });

            await Toast.fire({
              icon: 'error',
              title: 'Failed',
            });
          }
        }
      },
    };
  },
};
