
import { Actions } from '@/store/modules/PromotionBannerModule';
import { computed, onMounted, reactive, ref, toRefs, watch } from 'vue';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { dateFormat } from '@/utils/helper/format';
import { useStore } from 'vuex';
import BannerCard from './components/BannerCard.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import { hideModal } from '@/core/helpers/dom';
import Swal from 'sweetalert2/dist/sweetalert2.js';

const breadcrumbs = ['Promotions Banner', ['List']];

export default {
  name: 'Promotion Banner',
  components: {
    BannerCard,
    ConfirmModal,
  },
  setup() {
    const store = useStore();
    const tableRef = ref<any>(null);
    const modalRef = ref<any>(null);

    const state = reactive({
      promotionData: [] as any,
      loading: false,
      loaded: false,
      total: 0,
      rowsPerPage: 10,
      currentPage: 1,
      type: 'Fix',
      deleteBannerId: '',
    });

    watch(
      () => [
        tableRef.value?.pagination.rowsPerPage,
        tableRef.value?.pagination.page,
      ],
      async ([rows, page]) => {
        state.currentPage = page;
        state.rowsPerPage = rows;

        if (state.rowsPerPage + 1 > state.total) {
          state.currentPage = 1;
        }

        await fetch(state.currentPage, state.rowsPerPage);
      }
    );

    const fetch = async (page = 1, rowsPerPage = 10) => {
      try {
        state.loading = true;

        const { datas, total } = await store.dispatch(
          Actions.FETCH_PROMOTION_BANNER,
          {
            page,
            pageSize: rowsPerPage,
          }
        );

        state.promotionData = datas;
        state.total = total;

        state.loading = false;
      } catch (error) {
        state.loading = false;
      }
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs(
        breadcrumbs[0] as string,
        breadcrumbs[1] as Array<Record<string, unknown> | string>,
        {
          name: 'Add banner',
          path: '/promotion-banners/new',
        }
      );

      await fetch();
      state.loaded = true;
    });

    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: '',
      customClass: {
        popup: 'colored-toast',
      },
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    const setDeleteBannerId = (id: string) => {
      state.deleteBannerId = id;
    };
    const deleteBanner = async () => {
      if (state.deleteBannerId) {
        try {
          state.loading = true;
          await store.dispatch(
            Actions.DELETE_PROMOTION_BANNER_BY_ID,
            state.deleteBannerId
          );

          Toast.fire({
            icon: 'error',
            title: `Banner Deleted`,
          });

          state.promotionData = state.promotionData.filter(
            (promotion) => state.deleteBannerId !== promotion.id
          );

          state.deleteBannerId = '';
          hideModal(modalRef.value.$el);
          state.loading = false;
        } catch (error) {
          console.error({ error });

          state.deleteBannerId = '';
          hideModal(modalRef.value.$el);
          state.loading = false;
          Toast.fire({
            icon: 'error',
            title: 'Something went wrong, Cannot delete!',
          });
        }
      }
    };

    return {
      ...toRefs(state),
      modalRef,
      tableRef,
      breadcrumbs,
      dateFormat,
      setDeleteBannerId,
      deleteBanner,
      tableHeader: computed(() => {
        return [
          {
            key: 'title',
            name: 'Title',
            sortable: true,
          },
          {
            key: 'isActive',
            name: 'Status',
            sortable: false,
          },
          {
            key: 'startDate',
            name: 'Start',
            sortable: false,
          },
          {
            key: 'endDate',
            name: 'End',
            sortable: false,
          },
          {
            key: 'editView',
            name: 'Edit/View',
            sortable: false,
          },
        ];
      }),
    };
  },
};
